import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/translation'
// import { TRANSLATIONS_TR } from './tr/translation'
// import { TRANSLATIONS_RU } from './ru/translation'
i18n
  .use(initReactI18next)
  .init({
    resources: {
      English: {
        translation: TRANSLATIONS_EN
      },
      // Türkçe: {
      //   translation: TRANSLATIONS_TR
      // },
      // Pусский: {
      //   translation: TRANSLATIONS_RU
      // }
    },
    lng: 'English', // if you're using a language detector, do not define the lng option
    fallbackLng: 'English',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
// i18n.changeLanguage("zh");
export default i18n;