import React from 'react';
// import Loadable from 'react-loadable';
// const Loading = ({ error }) => {
//   if (error) {
//     return 'Oh nooess!';
//   } else {
//     return '';
//   }
// }
// const HomeScreen = Loadable({
//   loader: () => import(/* webpackChunkName: "HomePage" */'../pages/HomeScreen'),
//   loading: Loading
// });
const HomeScreen = React.lazy(() => import('../pages/HomeScreen'));
export const routePaths = {
  homeScreen: "/",
};
export const authRoutes = [
  {
    title: "HomeScreen",
    path: routePaths.homeScreen,
    url: routePaths.homeScreen,
    exact: true,
    element: <HomeScreen />
  },
];