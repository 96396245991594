import { routePaths } from '../../routes';
export const TRANSLATIONS_EN = {
  banking_reimagined: "Beyond Banking",
  benk_title1: "Get more from your",
  benk_title2: "money, by ",
  benk: "Benk",
  benk_subtitle1: "From easy money management to investing in startups, crypto, and beyond. Join our waitlist in a flash and get your first crypto, like Bitcoin or DOGE",
  benk_subtitle2: "",
  benk_subtitle3: " for free. ",
  get_started: "Get Started - ",
  it_s_free: " It's free",
  get_benk_now: "Get Benk Now:",
  k_download: "15K + Downloaded",
  download_benk: "Download Benk",
  tooltip_text_benk: "All Forward-looking statements involve risks and uncertainties, which may cause actual results to differ materially from the statements made here. Digital asset prices can be volatile. You are solely responsible for your decisions, and Benk.com and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.   BLU is not Benk's native token, it's merely in partnership with Benk. Please conduct your own due diligence before proceeding.",
  regulatory_disclosure: "Regulatory Disclosure",
  copyright_text: "Copyright ©️ 2023 Benk Limited. All rights reserved.",
  benk_footer_button_data: [
    {
      id: 1,
      title: "About",
    },
    {
      id: 2,
      title: "Contact",
    },
    {
      id: 3,
      title: "Terms of services",
    },
    {
      id: 4,
      title: "Privacy Policy",
      url: "mailto:info@benk.com"
    },
  ],
  navigation_link: [
    {
      id: 1,
      name: "Personal",
      //   to: routePaths.homeScreen,
      tooltipText: "It's on the way!",
      eventKey: "personal"
    },
    {
      id: 2,
      name: "Business",
      //   to: routePaths.homeScreen,
      tooltipText: "It's on the way!",
      eventKey: "business"
    },
    {
      id: 3,
      name: "Partners",
      //   to: routePaths.homeScreen,
      tooltipText: "It's on the way!",
      eventKey: "partners"
    },
    {
      id: 4,
      name: "Community",
      // to: "https://linktr.ee/benkglobal",
      eventKey: "community"
    },
  ],
}