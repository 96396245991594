import React, { useEffect } from "react";
import i18n from "./locales/i18n";
import Main from "./pages/Main";
function App() {
  useEffect(() => {
    i18n.changeLanguage('English');
  }, []);
  return (
      <Main/>
  );
}

export default App;
