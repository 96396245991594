import React from "react";
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { authRoutes } from '../routes';
const Main = React.memo(props => {
  return (
    <Router>
      <Routes>
        {
          authRoutes?.map((r, i) => (
            <Route
              key={i}
              path={r.path}
              exact={r.exact}
              render={props => <r.main {...props} />}
              element={r.element}
            />
          ))
        }
      </Routes>
    </Router>
  )
});
export default Main;